import * as Yup from "yup"

import { IPatient } from "models"
import { EMAIL_CONTACT_POINT_SYSTEM, PHONE_CONTACT_POINT_SYSTEM } from "utils"

import { PatientFormType } from "../types"

const getInitialPatientState = (orgId: string, practitioner: string): PatientFormType => ({
  firstName: "",
  middleName: "",
  lastName: "",
  gender: "",
  emails: [{ value: "", system: EMAIL_CONTACT_POINT_SYSTEM, use: "work" }],
  phones: [{ value: "", system: PHONE_CONTACT_POINT_SYSTEM, use: "work" }],
  birthdate: "",
  organization: orgId,
  practitioner: practitioner,
  address: { city: "", postalCode: "", line1: "", line2: "", state: "" },
})

const toPatient = ({ address, emails, phones, birthdate, ...rest }: PatientFormType): IPatient => {
  const telecom = [...emails, ...phones]
  const patientAddress = address.line1 ? [{ ...address, country: "US", use: "home" }] : undefined

  return {
    ...rest,
    address: patientAddress,
    telecom,
    birthdate,
  }
}

const patientValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  gender: Yup.string().required("Gender is required"),
  birthdate: Yup.date().required("Birthdate is required"),
  emails: Yup.array(
    Yup.object().shape({
      value: Yup.string().email("Valid email is required").required("Email is required"),
    }),
  ),
  phones: Yup.array(
    Yup.object().shape({
      value: Yup.string().required("Phone is required"),
    }),
  ),
  practitioner: Yup.string().required("Practitioner is required"),
  address: Yup.object().shape({
    line1: Yup.string().required("First address line is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    postalCode: Yup.string().required("ZIP is required"),
  }),
})

export { getInitialPatientState, toPatient, patientValidationSchema }
