import { useSuspenseQuery } from "@tanstack/react-query"

import { useClient } from "api"
import { IPractice } from "models"
import { useAuth } from "auth"

const useOrganization = () => {
  const { token } = useAuth()
  const { read } = useClient(token)
  const queryKey = ["practice"]

  const { data, isLoading } = useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      const organization = await read<IPractice>("practices")

      if (!organization) {
        throw new Error("Not found", {
          cause: {
            name: "404",
            message: "Organization not found",
          },
        })
      }
      return organization
    },
  })

  return { organization: data as IPractice, isLoading }
}

export { useOrganization }
