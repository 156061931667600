import React from "react"

import { AddressField, DropdownField, InputField, TelecomContainer } from "commons"
import { genders } from "utils"

const PractitionerForm: React.FC = () => (
  <div className="flex flex-col w-full space-y-3">
    <h5 className="my-10 text-2xl font-medium">Practitioner information</h5>

    <h6 className="text-lg w-full font-medium">Basic Information</h6>
    <hr className="bg-gray-300" />

    <div className="grid p-fluid lg:grid-cols-2 gap-4">
      <InputField field="practitioner.firstName" label="First Name*" />
      <InputField field="practitioner.lastName" label="Last Name*" />
    </div>

    <div className="grid p-fluid lg:grid-cols-2 gap-4">
      <InputField field="practitioner.middleName" label="Middle Name" />

      <DropdownField field="practitioner.gender" label="Gender" options={genders} />
    </div>

    <h6 className="text-lg w-full font-medium">Address</h6>
    <hr className="bg-gray-300" />

    <AddressField parentFieldName="practitioner" isRequired />

    <h6 className="text-lg w-full font-medium">Contact Information</h6>
    <hr className="bg-gray-300" />

    <TelecomContainer parentFieldName="practitioner" checkEmailExists />
  </div>
)

export { PractitionerForm }
