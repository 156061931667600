import React from "react"

import { AddressField, InputField, TelecomContainer } from "commons"

const OrganizationForm: React.FC = () => (
  <div className="flex flex-col w-full space-y-3">
    <h5 className="my-10 text-2xl font-medium">Organization information</h5>

    <h6 className="text-lg w-full font-medium">Basic Information</h6>
    <hr className="bg-gray-300" />

    <InputField field="organization.name" label="Organization Name*" />

    <h6 className="text-lg w-full font-medium">Address</h6>
    <hr className="bg-gray-300" />

    <AddressField parentFieldName="organization" isRequired />

    <h6 className="text-lg w-full font-medium">Contact Information</h6>
    <hr className="bg-gray-300" />

    <TelecomContainer parentFieldName="organization" />
  </div>
)

export { OrganizationForm }
