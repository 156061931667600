import { useQuery } from "@tanstack/react-query"

import { useClient } from "api"

import { PracticeSettings } from "../types"

const useSettings = (orgId: string) => {
  const { read } = useClient()

  const queryKey = ["settings", orgId]

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const settings = await read<PracticeSettings>(`aidbox/resolve-setting/${orgId}`)

      return settings
    },
    enabled: !!orgId,
  })

  return { settings: data, isLoading }
}

export { useSettings }
