import * as Yup from "yup"

import { IPractitioner, ITelecom } from "models"
import { EMAIL_CONTACT_POINT_SYSTEM, PHONE_CONTACT_POINT_SYSTEM } from "utils"
import { AddressFormErrorType, AddressFormType } from "commons"

const getInitialPractitionerState = (): PractitionerFormType => ({
  firstName: "",
  lastName: "",
  middleName: "",
  emails: [{ value: "", system: EMAIL_CONTACT_POINT_SYSTEM, use: "work" }],
  phones: [{ value: "", system: PHONE_CONTACT_POINT_SYSTEM, use: "work" }],
  address: { city: "", postalCode: "", line1: "", line2: "", state: "" },
})

const toPractitioner = ({ address, emails, phones, ...rest }: PractitionerFormType): IPractitioner => {
  const telecom = [...emails, ...phones]
  const practAddress = address.line1 ? [{ ...address, country: "US", use: "home" }] : undefined

  return {
    ...rest,
    address: practAddress,
    telecom,
  }
}

const practitionerValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  emails: Yup.array(
    Yup.object().shape({
      value: Yup.string().email("Valid email is required").required("Email is required"),
    }),
  ),
  phones: Yup.array(
    Yup.object().shape({
      value: Yup.string().required("Phone is required"),
    }),
  ),
  address: Yup.object().shape({
    line1: Yup.string().required("First address line is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    postalCode: Yup.string().required("ZIP is required"),
  }),
})

export type PractitionerFormType = {
  firstName: string
  middleName: string
  lastName: string
  gender?: string
  emails: ITelecom[]
  phones: ITelecom[]
  address: AddressFormType
}

export type PractitionerFormErrorType = {
  firstName: boolean
  lastName: boolean
  gender: boolean
} & AddressFormErrorType

export { getInitialPractitionerState, toPractitioner, practitionerValidationSchema }
