import { useState } from "react"
import { Button } from "primereact/button"
import { Form, Formik } from "formik"

import {
  getInitialPatientState,
  PatientForm,
  useCreatePatient,
  toPatient,
  patientValidationSchema,
  PatientFormType,
} from "patient"
import { ReponseRedirectType } from "hooks"
import { ONBOARD_VIEWS } from "utils"
import { usePractitioners } from "practitioner"
import { ApplySettings } from "settings"

import { Onboarded } from "./Onboarded"

const OnboardPatient: React.FC<IProps> = ({ orgId }) => {
  const { practitioners } = usePractitioners(orgId)
  const [redirectUrl, setRedirectUrl] = useState<ReponseRedirectType>()

  const handleOnSuccess = (response: ReponseRedirectType) => setRedirectUrl(response)

  const { createPatient, isLoading } = useCreatePatient(handleOnSuccess)

  const onSubmit = (patientFormValues: PatientFormType) => {
    const patient = toPatient(patientFormValues)
    createPatient(patient)
  }

  if (redirectUrl) {
    return (
      <>
        <ApplySettings orgId={orgId} isPatientPage />
        <Onboarded
          name={redirectUrl.name}
          redirectUrl={redirectUrl.redirectUrl}
          onboardView={ONBOARD_VIEWS.PATIENT}
          email={redirectUrl.email}
        />
      </>
    )
  }

  return (
    <Formik
      initialValues={getInitialPatientState(orgId, practitioners.length == 1 ? practitioners?.[0]?.value : "")}
      validationSchema={patientValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="w-full space-y-5" aria-autocomplete="none" autoComplete="off">
          <div className="flex flex-1 justify-end">
            <a type="button" className="hover:underline p-3" href={window.VITE_APP_PATIENT_PORTAL_URL}>
              <strong className="text-secondary">Already registered? Click here to Login</strong>
            </a>
          </div>
          <ApplySettings orgId={orgId} isPatientPage />
          <form className="w-full space-y-5">
            <PatientForm />
          </form>

          <hr className="bg-gray-300 my-10" />

          <div className="flex justify-end items-center w-full">
            <Button className="w-full md:w-min" type="submit" label="Save" loading={isSubmitting || isLoading} />
          </div>
        </Form>
      )}
    </Formik>
  )
}

type IProps = {
  orgId: string
}

export { OnboardPatient }
