import * as Yup from "yup"

import { IPractice, ITelecom } from "models"
import { EMAIL_CONTACT_POINT_SYSTEM, getEmails, getPhones, PHONE_CONTACT_POINT_SYSTEM } from "utils"
import { AddressFormErrorType, AddressFormType } from "commons"

const getInitialOrganizationState = ({ name, address, telecom }: IPractice): OrganizationFormType => {
  const phones = getPhones(telecom)
  const emails = getEmails(telecom)

  return {
    name,
    emails: !emails.length ? [{ value: "", system: EMAIL_CONTACT_POINT_SYSTEM, use: "work" }] : emails,
    phones: !phones.length ? [{ value: "", system: PHONE_CONTACT_POINT_SYSTEM, use: "work" }] : phones,
    address: {
      city: address[0].city,
      postalCode: address[0].postalCode,
      line1: address[0].line1,
      line2: address[0].line2 ?? "",
      state: address[0].state,
    },
  }
}

const toPractice = (practice: IPractice, { name, emails, phones, address }: OrganizationFormType): IPractice => {
  const telecom = [...emails, ...phones]

  return {
    ...practice,
    name,
    address: [{ ...practice.address[0], ...address, use: "billing", country: "US" }],
    telecom,
  }
}

const organizationValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  emails: Yup.array(
    Yup.object().shape({
      value: Yup.string().email("Valid email is required").required("Email is required"),
    }),
  ),
  phones: Yup.array(
    Yup.object().shape({
      value: Yup.string().required("Phone is required"),
    }),
  ),
  address: Yup.object().shape({
    line1: Yup.string().required("First address line is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    postalCode: Yup.string().required("ZIP is required"),
  }),
})

export type OrganizationFormType = {
  name: string
  emails: ITelecom[]
  phones: ITelecom[]
  address: AddressFormType
}

export type OrganizationFormErrorType = {
  name: boolean
} & AddressFormErrorType

export { getInitialOrganizationState, toPractice, organizationValidationSchema }
