import { useSuspenseQuery } from "@tanstack/react-query"

import { useClient } from "api"
import { ISelectItem } from "models"

const usePractitioners = (orgId: string) => {
  const { read } = useClient()
  const queryKey = ["practitioners"]

  const { data, isLoading } = useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      const practitioners = await read<ISelectItem[]>(`aidbox/practitioners/${orgId}`)

      return practitioners
    },
  })

  return { practitioners: data as ISelectItem[], isLoading }
}

export { usePractitioners }
