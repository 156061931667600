import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { useAuth } from "auth"
import { CustomError, displayNotificationError } from "errors"
import { IResourcesUpdate } from "models"

import { ReponseRedirectType } from "./types"
import { EMAIL_CONTACT_POINT_SYSTEM } from "utils"

const useUpdateResources = (onSuccess: (response: ReponseRedirectType) => void) => {
  const { token } = useAuth()
  const { update } = useClient(token)
  const queryClient = useQueryClient()

  const updateResources = async ({ practice, practitioner }: IResourcesUpdate) =>
    update<IResourcesUpdate, ReponseRedirectType>("practices", { practice, practitioner })

  const { mutate, data, isPending } = useMutation({
    mutationFn: updateResources,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["practice"] })
    },
    onError: (error: CustomError) => {
      displayNotificationError(error)
    },
    onSuccess: ({ redirectUrl }, { practice, practitioner }) =>
      onSuccess({
        redirectUrl,
        name: practice.name,
        email: practitioner.telecom.find(({ system }) => system === EMAIL_CONTACT_POINT_SYSTEM)?.value as string,
      }),
  })

  return { redirectUrl: data?.redirectUrl ?? "", updateResources: mutate, isUpdating: isPending }
}

export { useUpdateResources }
