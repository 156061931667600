import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBuilding } from "@fortawesome/pro-light-svg-icons"
import { useFormikContext } from "formik"

import { BirthdateField, TelecomContainer, AddressField, DropdownField, InputField } from "commons"
import { genders } from "utils"
import { usePractitioners } from "practitioner"
import { useOrganizationName } from "organization"

import { PatientFormType } from "../types"

const PatientForm = () => {
  const {
    values: { organization },
  } = useFormikContext<PatientFormType>()
  const { practitioners } = usePractitioners(organization)
  const { organizationName } = useOrganizationName(organization)

  return (
    <div className="flex flex-col w-full space-y-3">
      <div className="my-10">
        <h5 className="text-2xl font-medium">Patient Portal Registration</h5>
        <span title="Organization" className="text-xl text-slate-600">
          <FontAwesomeIcon icon={faBuilding} /> {organizationName ?? "No name"}
        </span>
      </div>

      <h6 className="text-lg w-full font-medium">Basic Information</h6>
      <hr className="bg-gray-300" />

      <DropdownField field="practitioner" label="Practitioner*" options={practitioners} />

      <div className="grid p-fluid lg:grid-cols-3 gap-4">
        <InputField field="firstName" label="First Name*" />
        <InputField field="middleName" label="Middle Name" />
        <InputField field="lastName" label="Last Name*" />
      </div>

      <div className="grid p-fluid lg:grid-cols-2 gap-4">
        <div className="field">
          <BirthdateField field="birthdate" label="Birthdate*" />
        </div>

        <DropdownField field="gender" label="Gender*" options={genders} />
      </div>

      <h6 className="text-lg w-full font-medium">Address</h6>
      <hr className="bg-gray-300" />

      <AddressField />

      <h6 className="text-lg w-full font-medium">Contact Information</h6>
      <hr className="bg-gray-300" />

      <TelecomContainer checkEmailExists patientOrganization={{ id: organization, name: organizationName ?? "" }} />
    </div>
  )
}

export { PatientForm }
