import { useQuery } from "@tanstack/react-query"

import { useClient } from "api"
import { ISelectItem } from "models"

const useUsaStates = () => {
  const { read } = useClient()
  const queryKey = ["usa-states", "continental-usa-states"]

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const states = await read<ISelectItem[]>("aidbox/value-set/continental-usa-states")

      return states
    },
  })

  return { usaStates: data as ISelectItem[], isLoading }
}

export { useUsaStates }
