import * as Yup from "yup"

import { IPractice } from "models"
import { getInitialOrganizationState, organizationValidationSchema } from "organization"
import { getInitialPractitionerState, practitionerValidationSchema } from "practitioner"

const getInitialValues = (org: IPractice) => ({
  organization: getInitialOrganizationState(org),
  practitioner: getInitialPractitionerState(),
})

const validationSchema = Yup.object().shape({
  organization: organizationValidationSchema,
  practitioner: practitionerValidationSchema,
})

export { getInitialValues, validationSchema }
