class Client {
  private apiUrl?: string
  private token?: string

  constructor(token?: string, apiUrl?: string) {
    this.apiUrl = apiUrl ? apiUrl : window.VITE_APP_API
    this.token = token
  }

  request = async (endpoint: string, { body, ...restConfig }: RequestInit) => {
    const config = {
      body,
      headers: {
        ...(this.token ? { Authorization: this.token } : {}),
        ...(body ? { "Content-Type": "application/json" } : {}),
      },
      ...restConfig,
    }

    const response = await fetch(`${this.apiUrl}/${endpoint}`, config)

    const data = await response.json().catch(() => {})

    if (response.ok) {
      return data
    }

    switch (response.status) {
      case 401:
        throw new Error("Unauthorized", { cause: { name: "401", message: "Unauthorized" } })
      case 404:
        throw new Error("Not found", { cause: { name: "404", message: response?.statusText ?? "Not found" } })
      case 403:
        throw new Error("Forbidden", { cause: { name: "403", message: "Forbidden" } })
      default:
        throw new Error("Not supported", {
          cause: { name: "not-supported", message: `Invalid response: ${response.statusText} ${response.status}` },
        })
    }
  }

  read = async <T>(endpoint: string) => {
    const data = await this.request(endpoint, {
      method: "GET",
    })

    return data as T
  }

  post = async <T, R = T>(endpoint: string, resource: T) => {
    const data = await this.request(endpoint, {
      method: "POST",
      body: JSON.stringify(resource),
    })

    return data as R
  }

  update = async <T, R = T>(endpoint: string, resource: T) => {
    const data = await this.request(endpoint, {
      method: "PUT",
      body: JSON.stringify(resource),
    })

    return data as R
  }
}

export { Client }
