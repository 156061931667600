import { useMutation } from "@tanstack/react-query"

import { useClient } from "api"
import { IPatient } from "models"
import { CustomError, displayNotificationError } from "errors"
import { ReponseRedirectType } from "hooks"
import { EMAIL_CONTACT_POINT_SYSTEM } from "utils"

const useCreatePatient = (onSuccess: (patient: ReponseRedirectType) => void) => {
  const { post } = useClient()

  const patient = async (patient: IPatient) => post<IPatient, ReponseRedirectType>("patients", patient)

  const { mutate, isPending } = useMutation({
    mutationFn: patient,
    onSuccess: ({ redirectUrl }, patient) =>
      onSuccess({
        redirectUrl,
        name: `${patient.firstName} ${patient.lastName}`,
        email: patient.telecom.find(({ system }) => system === EMAIL_CONTACT_POINT_SYSTEM)?.value as string,
      }),
    onError: (error: CustomError) => {
      displayNotificationError(error)
    },
  })

  return { createPatient: mutate, isLoading: isPending }
}

export { useCreatePatient }
