import { InputMask } from "primereact/inputmask"
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"
import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/pro-light-svg-icons"

import { emailValidation } from "utils"
import { useCheckEmailExists } from "hooks"
import { ErrorMessage, Field, FieldProps } from "formik"

const TelecomContainer: React.FC<IProps> = ({ parentFieldName, checkEmailExists, patientOrganization }) => {
  const parentFieldFullName = parentFieldName ? parentFieldName + "." : ""
  return (
    <div className="flex flex-col space-y-4">
      <EmailFields
        checkEmailExists={checkEmailExists}
        patientOrganization={patientOrganization}
        field={`${parentFieldFullName}emails[0].value`}
      />
      <PhoneFields field={`${parentFieldFullName}phones[0].value`} />
    </div>
  )
}

const EmailFields: React.FC<ITelecomFieldProps & IEmailProps> = ({
  field,
  checkEmailExists: checkEmail,
  patientOrganization,
}) => {
  const [exists, setExists] = useState(false)
  const { checkEmailExists, isCheckingEmail } = useCheckEmailExists(setExists)

  const validateField = async (value: string) => {
    const { isValid, msg } = emailValidation(value)
    if (!isValid) return msg

    if (checkEmail) {
      const existsMail = await checkEmailExists({ emailToCheck: value, patientOrganization })
      if (existsMail) return "This email address is in use"
    }

    return undefined
  }

  return (
    <Field name={field} validate={validateField}>
      {({ field: { name, value, onChange }, meta: { touched, error }, form: { setFieldTouched } }: FieldProps) => (
        <div className="flex items-center h-full relative">
          <div className="field w-full">
            <label htmlFor={name} className="font-medium text-gray-700 mb-2">
              Email*
            </label>
            <InputText
              id={name}
              name={name}
              autoComplete="off"
              aria-autocomplete="none"
              type="email"
              className={classNames("w-full p-inputtext-sm", {
                "p-invalid": touched && error,
              })}
              value={value}
              onChange={(e) => {
                setExists(false)
                onChange(e)
              }}
              onBlur={() => {
                if (checkEmail) {
                  const { isValid } = emailValidation(value)

                  if (isValid) {
                    setFieldTouched(field, true)
                    checkEmailExists({ emailToCheck: value, patientOrganization })
                  }
                }
              }}
            />
          </div>
          {!isCheckingEmail && touched && error && (
            <small className="p-error block absolute -bottom-3">
              <ErrorMessage name={field}>{(msg) => <span className="mr-2">{msg}</span>}</ErrorMessage>
              {exists && patientOrganization && (
                <a
                  className="text-xs text-blue-500 underline ml-1 cursor-pointer"
                  target="_blank"
                  href={window.VITE_APP_PATIENT_PORTAL_URL}
                  rel="noreferrer"
                >
                  Already registered? Click here to Login
                </a>
              )}
            </small>
          )}
          {isCheckingEmail && touched && (
            <small className="block absolute -bottom-3">
              <FontAwesomeIcon icon={faSpinner} spin className="mr-1" />
              <span>Checking email availability...</span>
            </small>
          )}
        </div>
      )}
    </Field>
  )
}

const PhoneFields: React.FC<ITelecomFieldProps> = ({ field }) => (
  <Field name={field}>
    {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
      <div className="flex items-center h-full relative">
        <div className="field w-full">
          <label htmlFor={name} className="font-medium text-gray-700 mb-2">
            Phone*
          </label>
          <InputMask
            id={name}
            name={name}
            type="tel"
            mask="+1 (999) 999-9999"
            unmask={true}
            className={classNames("w-full p-inputtext-sm", {
              "p-invalid": touched && error,
            })}
            value={value}
            onChange={onChange}
          />
        </div>
        <div className="p-error block absolute -bottom-3">
          <ErrorMessage name={field}>{(msg) => <small>{msg}</small>}</ErrorMessage>
        </div>
      </div>
    )}
  </Field>
)

type ITelecomFieldProps = {
  field: string
}

type IEmailProps = {
  checkEmailExists?: boolean
  patientOrganization?: { id: string; name: string }
}

type IProps = {
  parentFieldName?: string
  checkEmailExists?: boolean
  patientOrganization?: { id: string; name: string }
}

export { TelecomContainer }
