import { useSuspenseQuery } from "@tanstack/react-query"

import { useClient } from "api"

const useOrganizationName = (id: string) => {
  const { read } = useClient()
  const queryKey = ["practice-name", id]

  const { data, isLoading } = useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      const { name } = await read<{ name: string }>(`organizations/name-by-id/${id}`)

      return name
    },
  })

  return { organizationName: data, isLoading }
}

export { useOrganizationName }
