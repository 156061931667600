import { ITelecom } from "models"

import { TelecomErrorType } from "./types"

enum ONBOARD_VIEWS {
  ORGANIZATION,
  PATIENT,
}

const genders = [
  { value: "female", label: "Female" },
  { value: "male", label: "Male" },
]

const EMAIL_CONTACT_POINT_SYSTEM = "email"
const PHONE_CONTACT_POINT_SYSTEM = "phone"

const getEmails = (telecom: ITelecom[]) => telecom?.filter(({ system }) => system === EMAIL_CONTACT_POINT_SYSTEM)

const getPhones = (telecom: ITelecom[]) => telecom?.filter(({ system }) => system === PHONE_CONTACT_POINT_SYSTEM)

const emailValidation = (value: string) => {
  const check = { isValid: true, msg: "" }

  if (!value) {
    check.isValid = false
    check.msg = "Email address is required"
  } else {
    if (
      !/^([a-zA-Z0-9](\.[a-zA-Z0-9]|-[a-zA-Z0-9]|_[a-zA-Z0-9]|\+[a-zA-Z0-9]){0,})+@[a-zA-Z0-9]+((\.|-){0,1}[a-zA-Z])*$/.test(
        value,
      )
    ) {
      check.isValid = false
      check.msg = "Invalid email address"
    } else if (/@evexias.com/.test(value)) {
      check.isValid = false
      check.msg = "This email cannot be used"
    }
  }

  return check
}

const getInitialTelecomErrors = (emails: ITelecom[], phones: ITelecom[]) => ({
  emails: emails.map<TelecomErrorType>((email) => {
    if (!email.value) {
      return { hasError: true, msg: "Email address is required", isTouched: false }
    }

    if (!emailValidation(email.value)) {
      return { hasError: true, msg: "Wrong email", isTouched: false }
    }

    return { hasError: false, msg: "", isTouched: false }
  }),
  phones: phones.map<TelecomErrorType>((phone) => {
    if (!phone.value) {
      return { hasError: true, msg: "Phone number is required", isTouched: false }
    }

    return { hasError: false, msg: "", isTouched: false }
  }),
})

export {
  getEmails,
  getPhones,
  EMAIL_CONTACT_POINT_SYSTEM,
  PHONE_CONTACT_POINT_SYSTEM,
  ONBOARD_VIEWS,
  genders,
  emailValidation,
  getInitialTelecomErrors,
}
