import { faCheckCircle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { ONBOARD_VIEWS } from "utils"

import "./Onboarded.css"

const Onboarded: React.FC<IProps> = ({ name, onboardView, email }) => {
  /*const [counter, setCounter] = useState(COUNTER_REDIRECT_MAX)
  const intervalRef = useRef<NodeJS.Timer>()
  const counterValueRef = useRef<HTMLDivElement>(null)

  useEffect(() =>
  {
      intervalRef.current = setInterval(countDown, 1000)

      return () =>
      {
          clearInterval(intervalRef.current)
      }
  }, [])

  const navigateNow = useCallback(() =>
  {
      clearInterval(intervalRef.current)

      window.location.replace(redirectUrl)
  }, [redirectUrl, intervalRef])

  useEffect(() =>
  {
      if (counter === 0)
      {
          navigateNow()
      }
  }, [counter, redirectUrl, navigateNow])

  const countDown = useCallback(() =>
  {
      setCounter((prevCounter) =>
      {
          const newCounter = prevCounter - 1

          if (counterValueRef.current)
          {
              const color = newCounter <= COUNTER_REDIRECT_MAX / 2 ? "--yellow-500" : "--green-500"

              counterValueRef.current.style.background = `conic-gradient(var(${color}) ${(newCounter * 360 / COUNTER_REDIRECT_MAX)}deg, #ededed 0deg)`
          }

          return newCounter > 0 ? newCounter : 0
      })
  }, [setCounter])

  const discard = () =>
  {
      clearInterval(intervalRef.current)

      window.close()
  }*/

  const renderTitle = () => {
    switch (onboardView) {
      case ONBOARD_VIEWS.ORGANIZATION:
        return (
          <>
            Practice <span className="text-slate-500">{name}</span> has already been onboarded
          </>
        )
      case ONBOARD_VIEWS.PATIENT:
        return (
          <>
            Congratulations <span className="text-slate-500">{name}</span>, your account has been created
          </>
        )
      default:
        return <span></span>
    }
  }

  //   const renderAppName = () => {
  //     switch (onboardView) {
  //       case ONBOARD_VIEWS.ORGANIZATION:
  //         return "Admin"
  //       case ONBOARD_VIEWS.PATIENT:
  //         return "Patient Portal"
  //       default:
  //         return ""
  //     }
  //   }

  return (
    <div className="flex items-center justify-center h-full w-full">
      <div className="flex h-auto w-auto bg-white relative justify-center px-10 p-10 rounded-lg">
        <div className="absolute bg-primary -top-8 rounded-full shadow-md">
          <FontAwesomeIcon icon={faCheckCircle} size="4x" className="text-white" />
        </div>
        <div>
          <h3 className="text-3xl text-slate-500 text-center">Enrollment Successful!</h3>
          <p className="mt-10 text-lg">
            {renderTitle()}
            {email && (
              <span>
                . We have sent an email to <span className="text-slate-500">{email}</span> with a link to complete the
                registration process.
              </span>
            )}
          </p>

          {email && <p className="text-lg mt-5">Thank you!</p>}

          {/*<div className="flex items-center justify-center text-lg mt-5">
                    <div ref={counterValueRef} className="circular-progress mb-3 mr-5 flex items-center justify-center rounded-full relative w-14 h-14 before:absolute before:border-white before:border before:rounded-full before:w-10 before:h-10 before:bg-white mt-3">
                        <span className="flex relative text-xl text-black">{counter}</span>
                    </div>

                    <div className="justify-self-start">
                        <p>You are being redirected to <span className="font-semibold">{renderAppName()}</span> site</p>
                        <p>You could <span className="text-blue-500" role="button" onClick={discard}>discard</span> it or just <span className="text-blue-500" role="button" onClick={navigateNow}>go there</span> now</p>
                    </div>
                </div>*/}
        </div>
      </div>
    </div>
  )
}

type IProps = {
  redirectUrl: string
  name: string
  onboardView: ONBOARD_VIEWS
  email?: string
}

export { Onboarded }
