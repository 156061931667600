import { useState } from "react"
import { Button } from "primereact/button"

import { OrganizationForm, OrganizationFormType, toPractice, useOrganization } from "organization"
import { PractitionerForm, PractitionerFormType, toPractitioner } from "practitioner"
import { ONBOARD_VIEWS } from "utils"
import { ReponseRedirectType, useUpdateResources } from "hooks"
import { ApplySettings } from "settings"

import { Onboarded } from "./Onboarded"
import { Form, Formik, setNestedObjectValues } from "formik"
import { getInitialValues, validationSchema } from "./validation"

const OnboardOrganization: React.FC = () => {
  const { organization } = useOrganization()
  const [redirectData, setRedirectData] = useState<ReponseRedirectType>()
  const { updateResources, isUpdating } = useUpdateResources(setRedirectData)
  const [step, setStep] = useState(1)

  const onSubmit = ({
    organization: orgValues,
    practitioner,
  }: {
    organization: OrganizationFormType
    practitioner: PractitionerFormType
  }) => {
    const pract = toPractitioner(practitioner)
    const practice = toPractice(organization, orgValues)
    updateResources({ practice, practitioner: pract })
  }

  return (
    <>
      <ApplySettings orgId={organization.id} />
      {organization.active === false ? (
        <Formik initialValues={getInitialValues(organization)} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ isSubmitting, values, setTouched, validateForm }) => {
            const nextStep = async () => {
              await setTouched(setNestedObjectValues({ organization: values.organization }, true), true)
              const result = await validateForm()
              if (!result.organization) setStep(2)
            }

            return (
              <Form className="w-full space-y-5" aria-autocomplete="none" autoComplete="off">
                {step === 1 ? <OrganizationForm /> : <PractitionerForm />}
                <hr className="bg-gray-300 my-10" />
                <div className="flex justify-end items-center w-full space-x-5">
                  {step === 1 ? (
                    <Button className="w-full md:w-min" type="button" label="Next" onClick={nextStep} />
                  ) : (
                    <>
                      <Button
                        className="w-full md:w-min"
                        type="button"
                        label="Back"
                        onClick={() => setStep(1)}
                        disabled={isUpdating || isSubmitting}
                      />
                      <Button
                        className="w-full md:w-min"
                        type="submit"
                        label="Finish"
                        loading={isUpdating || isSubmitting}
                      />
                    </>
                  )}
                </div>
              </Form>
            )
          }}
        </Formik>
      ) : (
        <Onboarded
          redirectUrl={redirectData?.redirectUrl as string}
          name={organization.name}
          onboardView={ONBOARD_VIEWS.ORGANIZATION}
          email={redirectData?.email as string}
        />
      )}
    </>
  )
}

export { OnboardOrganization }
