import { FC, useEffect, useRef } from "react"

import { useUsaStates } from "value-set"

import { AddressFormType } from "./types"
import { useFormikContext } from "formik"
import { InputField } from "./InputField"
import { DropdownField } from "./DropdownField"

const AddressField: FC<IProps> = ({ parentFieldName, isRequired, showAutoComplete = true }) => {
  const { usaStates } = useUsaStates()
  const inputRef = useRef<HTMLInputElement>(null)
  const { setFieldValue } = useFormikContext<AddressFormType>()
  const parentFieldFullName = parentFieldName ? parentFieldName + "." : ""

  useEffect(() => {
    if ("google" in window) {
      const options: {
        componentRestrictions: { country: string }
        fields: string[]
        types: string[]
        bounds?: google.maps.LatLngBounds
        strictBounds?: boolean
      } = {
        componentRestrictions: { country: "us" },
        fields: ["address_components"],
        types: ["address"],
      }

      try {
        const autoComplete: google.maps.places.Autocomplete = new google.maps.places.Autocomplete(
          inputRef.current as HTMLInputElement,
          options,
        )

        autoComplete.addListener("place_changed", () => {
          const { address_components } = autoComplete.getPlace()
          let route = ""
          let streetNumber = ""

          for (const component of address_components as google.maps.GeocoderAddressComponent[]) {
            const componentType = component.types[0]

            switch (componentType) {
              case "route": {
                route = component.long_name
                break
              }

              case "street_number": {
                streetNumber = component.long_name
                break
              }

              case "postal_code": {
                setFieldValue(`${parentFieldFullName}address.postalCode`, component.long_name)
                break
              }

              case "locality":
                setFieldValue(`${parentFieldFullName}address.city`, component.long_name)
                break

              case "administrative_area_level_1": {
                setFieldValue(`${parentFieldFullName}address.state`, component.short_name)
                break
              }
            }
          }

          setFieldValue(`${parentFieldFullName}address.line1`, `${streetNumber} ${route}`)
        })
      } catch {}
    }
  }, [setFieldValue])

  return (
    <>
      <div className="grid p-fluid lg:grid-cols-2 gap-4">
        <InputField
          field={`${parentFieldFullName}address.line1`}
          label={`Line 1${isRequired ? "*" : ""}`}
          type="text"
          autocomplete="off"
          spellcheck={false}
          ref={showAutoComplete && "google" in window ? inputRef : null}
        />
        <InputField
          field={`${parentFieldFullName}address.line2`}
          label={`Line 2${isRequired ? "*" : ""}`}
          type="text"
          autocomplete="off"
          spellcheck={false}
        />
      </div>

      <div className="grid p-fluid md:grid-cols-3 gap-4">
        <InputField
          field={`${parentFieldFullName}address.city`}
          label={`City${isRequired ? "*" : ""}`}
          type="text"
          autocomplete="off"
          spellcheck={false}
        />
        <DropdownField field={`${parentFieldFullName}address.state`} label="State" options={usaStates} />
        <InputField
          field={`${parentFieldFullName}address.postalCode`}
          label={`Postal Code${isRequired ? "*" : ""}`}
          type="text"
          autocomplete="off"
          spellcheck={false}
        />
      </div>
    </>
  )
}

interface IProps {
  parentFieldName?: string
  isRequired?: boolean
  showAutoComplete?: boolean
}

export { AddressField }
