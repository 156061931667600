import { ErrorBoundary } from "react-error-boundary";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import {
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary,
} from "@tanstack/react-query";
import { PrimeReactProvider } from "primereact/api";
import { twMerge } from "tailwind-merge";

import { ErrorContainer } from "errors";
import { LoadingView } from "commons";

import "./main.css";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "./prime-react-custom-theme.css";
import "react-toastify/dist/ReactToastify.css";

import { App } from "./App";

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <PrimeReactProvider
      value={{
        pt: {},
        ptOptions: {
          mergeSections: true,
          mergeProps: true,
          classNameMergeFunction: twMerge,
        },
      }}
    >
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <QueryErrorResetBoundary>
            {({ reset }) => (
              <ErrorBoundary FallbackComponent={ErrorContainer} onReset={reset}>
                <Suspense fallback={<LoadingView />}>
                  <App />
                </Suspense>
              </ErrorBoundary>
            )}
          </QueryErrorResetBoundary>
        </QueryClientProvider>
      </BrowserRouter>
    </PrimeReactProvider>
  </React.StrictMode>
);
